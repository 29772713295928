import React from "react"
import styled from "styled-components"
import { Container } from "./utils"
import SectionHeader from "./sectionHeader"
import DesignTokens from "./designTokens"
import { Caption, Lead } from "./typography"
import { ItemsList } from "./utils"
import RichContentBlockText from "./richContentBlockText"

const TeamBlock = ({ content }) => {
  return (
    <Wrapper>
      <CustomContainer>
        <SectionHeader title={content.title} />
        <CustomList>
          {content.people.map(person => (
            <li key={person.id}>
              {person.image && (
                <img src={person.image.url} alt={person.image.alt} />
              )}
              <Lead>{person.name}</Lead>
              <Caption color={DesignTokens.colors.primary[500]}>
                {person.role}
              </Caption>
              <RichContentBlockText html={person.bio} />
            </li>
          ))}
        </CustomList>
        <Lead>{content.subtitle}</Lead>
      </CustomContainer>
    </Wrapper>
  )
}

const CustomList = styled(ItemsList)`
  display: flex;
  flex-direction: row;
  & > li {
    flex: 1 0 0px;
    text-align: left;
    margin: 0 ${DesignTokens.spacing[5]};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
const CustomContainer = styled(Container)`
  & > * {
    margin-bottom: ${DesignTokens.spacing[10]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`


const Wrapper = styled.div`
  padding: 6rem 0;
  text-align: center;
`

export default TeamBlock

import React from "react"
import styled from "styled-components"
import parse, { domToReact } from "html-react-parser"
import { Caption } from "./typography"
import DesignTokens from "./designTokens"
import { ItemsList } from "./utils"

const HighlightsBlock = ({ html, title }) => {
  const options = {
    replace: ({ name, children }) => {
      switch (name) {
        case "ul":
          return <List>{domToReact(children)}</List>
      }
    },
  }
  return (
    <Wrapper>
      <Caption color={DesignTokens.colors.primary[500]}>{title}</Caption>
      {parse(html, options)}
    </Wrapper>
  )
}

const List = styled(ItemsList)`
  display: flex;
  flex-direction: row;
  & > li {
    color: ${DesignTokens.colors.primary[500]};
    margin-right: 4%;
    margin-top: 2%;
    padding-bottom: 2%;
    font-size: ${DesignTokens.fontSizes[5]};
    &:last-child {
      margin-right: 0;
    }
    border-bottom: 1px dashed ${DesignTokens.colors.primary[500]};
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    & > li {
      margin: 0 0 1rem 0;
      padding-bottom: 1rem;
    }
  }
`

const Wrapper = styled.div``

export default HighlightsBlock
